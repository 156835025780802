<template>
  <button :type="type"
          :disabled="disabled"
          :class="{'disabled':disabled}">
    <slot>{{ label }}</slot>
  </button>
</template>

<script>
export default {
  name: "CustomButton",
  props: {
    label: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'submit'
    },
  },
}
</script>

<style lang="scss" scoped>
button {
  @apply focus:outline-none text-white text-xl font-medium py-2.5 px-8 rounded-3xl bg-green-800 hover:bg-green-600 hover:shadow-lg transition-all duration-100 ease-linear;

  &:disabled{
    @apply bg-gray-300 opacity-50 cursor-not-allowed;
  }
}
</style>
<template>
  <div>
    <transition name="fade" mode="out-in">
      <section class="contact-form" v-if="!showGreetings">
        <form @submit.prevent="onSubmit">
          <div class="section" :class="{visible:showForm}">
            <label for="name" class="form__label">Full Name*</label>
            <input id="name" v-model="form.name" class="form__input" type="text" name="name">
          </div>
          <div class="section" :class="{visible:showForm}">
            <label for="email" class="form__label">Email*</label>
            <input id="email" v-model="form.email" class="form__input" type="email" name="email">
          </div>
          <div class="section" :class="{visible:showForm}">
            <label for="phone" class="form__label">Telephone</label>
            <input id="phone" v-model="form.phone" class="form__input" type="text" name="phone">
          </div>
          <div class="section" :class="{visible:showForm}">
            <label for="message" class="form__label">Message*</label>
            <textarea id="message" v-model="form.message" style="resize: none;" rows="5" class="form__input"
                      name="message"></textarea>
          </div>
          <div class="section" :class="{visible:showForm}">
            <transition name="scale" mode="out-in">
              <div class="w-full text-center">
            <span v-if="validationErrors != null" class="text-lg text-center text-red-600">
              {{ validationErrors }}
            </span>
              </div>
            </transition>
            <div class="flex w-full pt-4 justify-center">
              <CustomButton label="Send message" :disabled="validationErrors != null"/>
            </div>
          </div>

        </form>
      </section>
      <section v-else class="flex w-full">
        <div class="m-auto flex flex-col text-center max-w-screen-md">
          <span class="text-3xl">Message send!</span>
          <span
              class="text-3xl pt-4">Your message has been successfully sent, you will be contacted as soon as possible</span>
        </div>
      </section>
    </transition>
  </div>
</template>

<script>
import {onMounted, ref, reactive, computed} from "vue";
import emailjs from 'emailjs-com';
import CustomButton from "@/components/CustomButton";

export default {
  name: "ContactForm",
  components: {CustomButton},
  setup() {
    const showForm = ref(false)
    const showGreetings = ref(false)
    const form = reactive({
      name: "",
      email: "",
      phone: "",
      message: ""
    })
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera

    onMounted(() => {
      setTimeout(function () {
        showForm.value = true;
      }, 100);
    })

    const validationErrors = computed(() => {
      if (form.name.trim() === '') {
        return 'Name field is required'
      } else if (form.email.trim() === '') {
        return 'Email field is required'
      } else if (!/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(form.email)) {
        return 'Please enter a valid email'
      } else if (form.message.trim() === '') {
        return 'Message field is required'
      } else {
        return null
      }
    });

    /**
     * @desc Handle scroll function
     */
    const onSubmit = (e) => {

      try {
        emailjs.sendForm('service_4ypfov8', 'template_vlsvg89', e.target,
            'user_1S73C84HIX2YI5B0YpaGH', {
              name: form.name,
              email: form.email,
              phone: form.phone,
              reply_to: form.email,
              message: form.message
            })

      } catch (error) {
        console.error({error})
      } finally {
        showGreetings.value = true
        form.name = ''
        form.email = ''
        form.phone = ''
        form.message = ''
      }

    }

    return {
      showForm,
      showGreetings,
      form,
      validationErrors,
      onSubmit,
    }
  }
}
</script>

<style lang="scss" scoped>
.contact-form {
  @apply w-full;

  form {
    @apply w-full h-full;

    .section {
      @apply w-full;
      font-size: 1.25em;
      text-align: left;
      margin: 0 auto;
      transform: translate3d(0, 100px, 0);
      opacity: 0;
      transition: transform 0s 0.6s, opacity 0s 0.6s;

      &.visible {
        opacity: 1;
        transform: translate3d(0, 0, 0);
        transition: transform 1s 0.3s, opacity 1s 0.3s;
        transition-timing-function: cubic-bezier(0.2, 1, 0.2, 1);

        &:nth-child(2) {
          transition-delay: 0.35s;
        }

        &:nth-child(3) {
          transition-delay: 0.40s;
        }

        &:nth-child(4) {
          transition-delay: 0.45s;
        }

        &:nth-child(5) {
          transition-delay: 0.50s;
        }

        &:nth-child(6) {
          transition-delay: 0.55s;
        }
      }

      label {
        display: block;
        flex: none;
        width: 100%;
        padding: 0.25em 0;
      }

      input, textarea {
        padding: 1vh;
        font-size: 1.5em;
        width: 100%;
        border: 2px solid;
        font-weight: bold;
        background: transparent;

        &:focus {
          outline: none;
        }
      }
    }
  }
}
</style>
<template>
  <div class="contact-page">
    <!-- CONTACT ME -->
    <section class="flex w-full pt-20 px-4 text-white">
      <div class="grid grid-cols-1 gap-6 max-w-screen-md m-auto w-full">
        <h2 class="text-3xl md:text-6xl text-center font-bold">Direct contact</h2>
        <div class="grid grid-cols-4 gap-4 bg-white p-8 rounded-3xl ">
          <SocialLink type="linkedin"/>
          <SocialLink type="twitter"/>
          <SocialLink type="github"/>
          <SocialLink type="telegram"/>
        </div>
      </div>
    </section>

    <!-- KEEP IN TOUCH -->
    <section class="flex w-full py-10 md:py-20 px-4 text-white">
      <div class="grid grid-cols-1 gap-6 max-w-screen-md m-auto w-full">
        <h2 class="text-center text-3xl md:text-6xl font-bold">Keep in touch</h2>
        <ContactForm/>
      </div>
    </section>
  </div>
</template>

<script>

import ContactForm from "@/modules/contact/components/ContactForm";
import SocialLink from "@/components/SocialLink";

export default {
  name: "contact",
  components: {SocialLink, ContactForm},
}
</script>

<style lang="scss" scoped>

</style>